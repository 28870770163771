<template>
  <v-card
    outlined
  >
    <TransientDash
      :key="coupon.uuid"
      :title="$t('redemptions')"
      namespace="coupon_sales_list"
      :config="couponValueConfig"
    />
  </v-card>
</template>

<script>
import TransientDash from '@/components/dash/TransientDash.vue'

export default {
  name: "CouponRedemptions",
  components: {
    TransientDash
  },
  props: {
    coupon: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    tile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    couponValueConfig() {

      if(this.coupon === null || this.coupon === undefined) {
        return {}
      }

      return {
        couponUUID: this.couponUUID
      }
    },
    couponUUID() {
      return this.coupon.uuid
    }
  },  
  methods: {
    close() {
      this.$emit('close')
    }
  },
}
</script>

<style scoped>

</style>
